import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import parse from "html-react-parser"
import Link from "../../components/link"
import Title from "../../components/title"
import { formatEventDate } from "../../utils/date-utils"
import { parseISO } from "date-fns"

export const query =  graphql`
  query TEMPLATE_WP_ENTERTAINMENT_QUERY(
    $id: String!
  ) {
    wpPage(id: { eq: $id }) {
      ...pageData
      ...pageVariables
    }
    allWpEvent(
      filter: {status: {eq: "publish"}}
    ) {
      nodes {
        ...eventData
        ...eventVariables
        ...eventFeaturedImage
      }
      pageInfo {
        currentPage
        hasPreviousPage
        hasNextPage
      }
    }
    allWpPost(
      skip: 0
      limit: 1
      filter: {status: {eq: "publish"}}
      sort: {fields: dateGmt, order: DESC}
    ) {
      nodes {
        ...postData
        ...postFeaturedImage
      }
      pageInfo {
        currentPage
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

const Page = ({ data = {} }) => {
  const { wpPage: page = {}, allWpEvent: { nodes: events = [] }, allWpPost: { nodes: posts = [] }} = data
  const { 
    pageVariables = {},
    pageMeta = {},
    smartcrawlSeo = {} 
  } = page
  const image = pageMeta.headerImage && convertToBgImage(getImage(page.pageMeta.headerImage.localFile))

  events.sort(function(a, b) {
    let dateA = parseISO(a.eventMeta.startDate);
    let dateB = parseISO(b.eventMeta.startDate);
    return (dateA < dateB) ? -1 : ((dateA > dateB) ? 1 : 0);
  })
  const latestEvent = events.slice(0, 1);
 
  return (
    <React.Fragment>
      <Seo post={data.wpPage} />
      {pageVariables.primaryColour && 
        <style dangerouslySetInnerHTML= {{__html: `
        :root {
          --bs-primary: ${pageVariables.primaryColour};
        }
        `}} />
      }
      <main>
        {image &&
          <BackgroundImage 
            {...image} 
            className="header-image"
          />
        }
        
        <Title title={page.title} />

        <div className="container py-5">
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>

        <div className="section-box-full">
          <StaticImage
            src="../../assets/images/events-calendar.jpg"
            alt="Entertainment"
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            objectFit="cover"
            objectPosition="bottom left"
          />
          <div className="container">
            <div className="carousel-overlay p-4">
              <h2>Event <br/>Calendar</h2><br/>
              <Link className="btn btn-secondary" to="/whats-on/events/">
              See calendar...
              </Link>
            </div>
          </div>
        </div>

        {latestEvent && latestEvent.length > 0
          ? (
            latestEvent.map((node) => {
              const {
                id = '',
                title = '',
                excerpt = '',
                uri = '',
                eventMeta = {},
                featuredImage = {}
              } = node
      
              const eventImage = featuredImage && getImage(featuredImage.node.localFile)
      
              return (
                <div className="container py-5" key={id}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <GatsbyImage image={eventImage} alt={title} />
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                      <div>
                        <h2>{title}</h2>
                        <p>{formatEventDate(eventMeta.startDate, eventMeta.endDate)}</p>
                        <p>{parse(excerpt)}</p>
                        <Link to={uri} className="btn btn-primary">Read more...</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="container py-5 text-center">
              <h2>Events coming soon...</h2>
              <p>Currently we don't have any upcoming events. Keep watching our website and social media for updates on this years events calendar and even more exciting announcements…!</p>
            </div>
          )
        }

      </main>
    </React.Fragment>
  )
}

export default Page